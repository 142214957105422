import axios from 'axios';
const cbtexamsubject = {    
	actions: {
        processcbtexamsubject(context, UserData){
			return new Promise((resolve, reject) => {				
				axios({url: '/Cbtexamsubject/Processcbtexamsubject', data: UserData, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
        getcbtexamsubject({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Cbtexamsubject/Getcbtexamsubject', data: {id: Id, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},			
		getcbtexamsubjectsearch({rootState}, {Id,item}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Cbtexamsubject/Getcbtexamsubjectsearch', data: {id: Id, searchitem: item, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getcbtexamsubjectbyid({rootState},{Id,ExamId}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Cbtexamsubject/Getcbtexamsubjectbyid', data: {id: Id, idd:ExamId, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
       
	}
}

export default cbtexamsubject