import axios from 'axios';

const homework = {
    state: {  
		homeworks : []
	},	
	mutations: {		
		homeworks_success(state, payload){			
			state.homeworks = payload	
		}	
	},
	actions: {
        gethomework(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Homework/Gethomework', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {						
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},			
		gethomeworkbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Homework/Gethomeworkbyid', data: {id: Id, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		gethomeworkforstudent({rootState},{StudId,SubjectId}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Homework/Gethomeworkforstudent', data: {id: StudId, idd: SubjectId, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		gethomeworkanswer({rootState},HomeworkId){
			return new Promise((resolve, reject) => {				
				axios({url: '/Homework/Gethomeworkanswer', data: {id: HomeworkId, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		gethomeworkanswerbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Homework/Gethomeworkanswerbyid', data: {id: Id, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		gethomeworkanswerbystudid({rootState},{HomeworkId,StudId}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Homework/Gethomeworkanswerbystudid', data: {id: HomeworkId, idd:StudId, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},							
		homeworkregistration(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Homework/Registerhomework', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		processhomeworkanswer(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Homework/Processhomeworkanswer', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        },    
               
	}
}

export default homework