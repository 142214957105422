<template>
<side-bar :background-color="sidebarBackground" short-title="SchoolDock" title="SchoolDock">
    <template slot="links">
        <sidebar-item :link="{name: 'Dashboard', icon: 'ni ni-tv-2 text-primary', path: '/dashboard'
            }" />
        <sidebar-item :link="{name: 'School', icon: 'ni ni-building text-blue', path: '/school'}" />
        <sidebar-item :link="{name: 'School Subject', icon: 'ni ni-ruler-pencil text-blue', path: '/subjectsetup'}" />
        <sidebar-item :link="{name: 'Staff', icon: 'fa fa-users text-blue', path: '/supportstaff'}" v-if="$store.state.auth.user_status_id==6" />        
        <sidebar-item :link="{name: 'CBT', icon: 'ni ni-tv-2 text-blue', path: '/cbtexam'}" v-if="$store.state.auth.user_status_id!=11" />
        <sidebar-item :link="{name: 'News', icon: 'ni ni-world text-blue', path: '/news'}" v-if="$store.state.auth.user_status_id!=11" />

        <sidebar-item :link="{name: 'Policy', icon: 'ni ni-tag text-blue', path: '/privacysetup'}" v-if="$store.state.auth.user_status_id!=11" />

    </template>
</side-bar>
</template>
<script>
export default {
     data() {
      return {
        sidebarBackground: 'vue' //vue|blue|orange|green|red|primary
      };
    },
}
</script>
