var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.auth.user_status_id == 1)?_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":"SchoolDock","title":"SchoolDock"}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
        name: 'Dashboard',
        icon: 'fa fa-tv text-primary',
        path: '/dashboard',
      }}}),(_vm.$store.state.usersdata.staffdetails.IsRegistral == true)?_c('sidebar-item',{attrs:{"link":{
        name: 'Registrar',
        icon: 'fa fa-id-card text-blue',
        path: '/registrar',
      }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
        name: 'Academic',
        icon: 'fa fa-chalkboard-teacher text-blue',
        path: '/academic',
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: 'Student Record',
        icon: 'fa fa-clipboard text-blue',
        path: '/student',
      }}}),(_vm.$store.state.usersdata.staffdetails.IsHostelMaster==true)?_c('sidebar-item',{attrs:{"link":{
        name: 'Hostel',
        icon: 'fa fa-bed text-blue',
        path: '/hostel',
      }}}):_vm._e(),(_vm.$store.state.usersdata.staffdetails.IsLibraryAdmin==true)?_c('sidebar-item',{attrs:{"link":{
        name: 'Library',
        icon: 'fa fa-book-reader text-blue',
        path: '/library',
      }}}):_vm._e(),(_vm.$store.state.usersdata.staffdetails.IsBursal == true)?_c('sidebar-item',{attrs:{"link":{
        name: 'Finance',
        icon: 'fa fa-cash-register text-blue',
        path: '/finance',
      }}}):_vm._e(),(_vm.$store.state.usersdata.staffdetails.IsPortalAdmin == true)?_c('sidebar-item',{attrs:{"link":{
        name: 'Notification',
        icon: 'fa fa-bell text-blue',
        path: '/notification',
      }}}):_vm._e(),(_vm.$store.state.usersdata.staffdetails.IsPortalAdmin == true)?_c('sidebar-item',{attrs:{"link":{
        name: 'Chat',
        icon: 'fab fa-rocketchat text-blue',
        path: '/notification',
      }}}):_vm._e(),(_vm.$store.state.usersdata.staffdetails.IsPortalAdmin == true)?_c('sidebar-item',{attrs:{"link":{
        name: 'SMS',
        icon: 'fas fa-sms text-blue',
        path: '/smsmenu',
      }}}):_vm._e(),(_vm.$store.state.usersdata.staffdetails.IsPortalAdmin == true)?_c('sidebar-item',{attrs:{"link":{
        name: 'Website',
        icon: 'fa fa-window-restore text-blue',
        path: '/schsitemenu',
      }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
        name: 'Report',
        icon: 'fa fa-chart-bar text-blue',
        path: '/reportmenu',
      }}}),(_vm.$store.state.usersdata.staffdetails.IsPortalAdmin == true)?_c('sidebar-item',{attrs:{"link":{
        name: 'Setting',
        icon: 'fa fa-sliders-h text-blue',
        path: '/setting',
      }}}):_vm._e(),(_vm.$store.state.usersdata.staffdetails.IsPortalAdmin == true)?_c('sidebar-item',{attrs:{"link":{
        name: 'Subscription',
        icon: 'fa fa-money-check text-blue',
        path: '/subscription',
      }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
        name: 'Profile',
        icon: 'fa fa-user text-blue',
        path: '/profile',
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: 'Help',
        icon: 'fa fa-question text-blue',
        path: '/dashboard',
      }}})],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }