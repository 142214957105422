<template>
<div class="wrapper">
    <div class="main-content bg-default">
        <base-nav class="navbar-top navbar-horizontal navbar-dark" containerClasses="px-4 container" expand>
            <router-link slot="brand" class="navbar-brand" to="/home">
                <img src="img/brand/white.png" />
            </router-link>

            <template v-slot="{closeMenu}">
                <!-- Collapse header -->
                <div class="navbar-collapse-header d-md-none">
                    <div class="row">
                        <div class="col-6 collapse-brand">
                            <router-link to="/home">
                                <img src="img/brand/green.png">
                            </router-link>
                        </div>
                        <div class="col-6 collapse-close">
                            <button type="button" @click="closeMenu" class="navbar-toggler" aria-label="Toggle sidenav">
                                <span></span>
                                <span></span>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Navbar items -->
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <router-link to="/home" class="btn btn-default btn-block">
                            <span class="nav-link-inner--text"><i class="fa fa-server"></i> Access School Portal</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                    <router-link to="/register" class="btn btn-primary btn-block">
                            <span class="nav-link-inner--text"><i class="fa fa-laptop"></i> Prepare for Exam</span>
                        </router-link>
                    </li>
                </ul>
            </template>
        </base-nav>
        <!-- Header -->
        <div class="header bg-light py-7 py-lg-8">
            <div class="container">

            </div>
            <div class="separator separator-bottom separator-skew zindex-100">
                <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
                </svg>
            </div>
        </div>
        <!-- Page content -->
        <div class="container mt--8 pb-5">
            <slide-y-up-transition mode="out-in" origin="center top">
                <router-view></router-view>
            </slide-y-up-transition>
        </div>
        <footer class="py-5">
            <div class="container">
                <div class="row align-items-center justify-content-xl-between">
                    <div class="col-xl-6">
                        <div class="copyright text-center text-xl-left text-muted">
                            &copy; {{year}} <a href="#" class="font-weight-bold ml-1">SchoolDock</a>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <ul class="nav nav-footer justify-content-center justify-content-xl-end">
                            <li class="nav-item">
                                <a href="#" class="nav-link" target="_blank">FAQ</a>
                            </li>
                            <li class="nav-item">
                                <a href="#" class="nav-link" target="_blank">Term & Condition</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</div>
</template>

<script>
import {
    SlideYUpTransition
} from 'vue2-transitions'

export default {
    name: 'auth-layout',
    components: {
        SlideYUpTransition,

    },
    data() {
        return {
            userinfo: {},
            year: new Date().getFullYear(),
            showMenu: false
        }
    },
    methods: {
        getuser: function () {
            this.onloader = 'flex';
            this.$store.dispatch('getuser')
                .then((resp) => {
                    this.userinfo = resp;
                    this.onloader = 'none';
                })
                .catch(() => {
                    this.onloader = 'none';
                    this.$store.dispatch('logout')
                    this.$router.push('/login')
                })
        },
        logout: function () {
            this.$store.dispatch('logout')
                .then(() => {
                    this.$router.push('/login')
                })
        },
    },
    mounted() {
        this.getuser();
    }
}
</script>

<style>
</style>
