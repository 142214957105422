import axios from 'axios';

const schclass = {
    state: {  
		classcategory : [],
		schclass : []
	},	
	mutations: {		
		class_success(state, payload){			
			state.schclass = payload	
		},
		classcategory_success(state, payload){			
			state.classcategory = payload	
		}		
	},
	actions: {
        getclasscategory({commit, rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schclass/Getclasscategory', data: {id: '1', searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('classcategory_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		getclass({commit, rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schclass/Getclass', data: {id: '1', searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('class_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		getclassinclassassigned({rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schclass/Getclassinclassassigned', data: {id: '1', searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		getclassinsubjectassigned({rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schclass/Getclassinsubjectassigned', data: {id: '1', searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {							
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		getclasssearch({commit, rootState}, item){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schclass/Getclasssearch', data: {id: '1', searchitem: item, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('class_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getclassbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schclass/Getclassbyid', data: {id: Id, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		classregistration({commit}, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schclass/Registerclass', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('class_success',payload);					
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		getclassforstudentreport(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schclass/Getclassforstudentreport', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		getclassforstudentreportmid(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schclass/Getclassforstudentreportmid', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		GetClassInSchoolBill({rootState},{SessionId,TermId}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Schclass/GetClassInSchoolBill?SessionId=${SessionId}&TermId=${TermId}&Username=${rootState.auth.token}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
	}
}

export default schclass