import axios from 'axios';

const tcquestion = {   
	actions: {
        gettcquestionheader(context, UserData){
			return new Promise((resolve, reject) => {				
				axios({url: '/Tcexam/Getschquestionheader', data: UserData, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		gettcquestionheaderbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Tcexam/Getschquestionheaderbyid', data: {id: Id, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		processtcquestionheader(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Tcexam/Processschquestionheader', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   								
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		sharetcquestion(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Tcexam/Shareschquestion', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   								
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		sharealltcquestion(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Tcexam/Shareallschquestion', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   								
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		deletetcquestion({rootState},{QuestionId,HeaderId}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Tcexam/Deleteschquestion', data: {id: QuestionId, idd: HeaderId, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		gettcquestion({rootState},HeaderId){
			return new Promise((resolve, reject) => {				
				axios({url: '/Tcexam/Getschquestion', data: {id: HeaderId, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		gettcquestionbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Tcexam/Getschquestionbyid', data: {id: Id, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		processtcquestion(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Tcexam/Processschquestion', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   								
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		gettcanswer({rootState},QuestionId){
			return new Promise((resolve, reject) => {				
				axios({url: '/Tcexam/Getschanswer', data: {id: QuestionId, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		processtcanswer(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Tcexam/Processschanswer', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   								
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        },        	
	}
}

export default tcquestion