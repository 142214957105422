import axios from 'axios';

const reportcard = {
   
	actions: {     
		getstudentforreportcardsubscriptionforpayment({rootState},{SessionId,TermId,SearchItem}){
			return new Promise((resolve, reject) => {				
				axios.get(`/ReportCardSubscription/GetStudentForReportCardSubscriptionforPayment?SessionId=${SessionId}&TermId=${TermId}&SearchItem=${SearchItem}&AccessKey=${rootState.auth.accesskey}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {								
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
		getstudentforreportcardandcbtsubscriptionforpayment({rootState},{SessionId,TermId,SearchItem}){
			return new Promise((resolve, reject) => {				
				axios.get(`/ReportCardSubscription/GetStudentForReportCardandCbtSubscriptionforPayment?SessionId=${SessionId}&TermId=${TermId}&SearchItem=${SearchItem}&AccessKey=${rootState.auth.accesskey}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {								
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
		deletestudentforreportcardsubscription({rootState},{Id,SessionId,TermId}){
			return new Promise((resolve, reject) => {				
				axios.get(`/ReportCardSubscription/DeleteStudentForReportCardSubscription?SessionId=${SessionId}&TermId=${TermId}&Id=${Id}&AccessKey=${rootState.auth.accesskey}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {								
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },		  
		processreportcardsubscription({rootState},TransactionRef){
			return new Promise((resolve, reject) => {				
				axios.get(`/ReportCardSubscription/ProcessReportCardSubscription?TransactionRef=${TransactionRef}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {								
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },	
		RevalidateReportCardSubscription({rootState},{SessionId,TermId}){
			return new Promise((resolve, reject) => {				
				axios.get(`/ReportCardSubscription/RevalidateReportCardSubscription?SessionId=${SessionId}&TermId=${TermId}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {								
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },	   			
				
	}
}

export default reportcard