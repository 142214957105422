import axios from 'axios';
const scharm = {
    state: {  
		scharms : []
	},	
	mutations: {		
		arm_success(state, payload){			
			state.scharms = payload	
		}	
	},
	actions: {
        getarm({commit, rootState},OptionId){
			return new Promise((resolve, reject) => {				
				axios({url: '/Scharm/Getarm', data: {id: OptionId, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('arm_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getarmsearch({commit, rootState}, item){
			return new Promise((resolve, reject) => {				
				axios({url: '/Scharm/Getarmsearch', data: {id: '1', searchitem: item, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('arm_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getarmbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Scharm/Getarmbyid', data: {id: Id, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getarminclassassigned({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Scharm/Getarminclassassigned', data: {id: Id, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {														
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		getarminsubjectassigned({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Scharm/Getarminsubjectassigned', data: {id: Id, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {														
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getarmbyclass({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Scharm/Getarmbyclass', data: {id: Id, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },		
        getarmbyoption(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Scharm/Getarmbyoption', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		armregistration({commit}, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Scharm/Registerarm', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('arm_success',payload);					
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}
	}
}

export default scharm