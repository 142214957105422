import axios from 'axios';

const alumni = {
    state: {  
		alumnis : []
	},	
	mutations: {		
		alumnis_success(state, payload){			
			state.alumnis = payload	
		}	
	},
	actions: {
        getalumni({commit, rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Alumni/Getalumni', data: {id: '1', searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	
                    commit('alumnis_success',respdata);						
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},			
		getalumnibysession({commit},userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Alumni/Getalumnibysession', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	
                    commit('alumnis_success',respdata);									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },	
        getalumnisearch({commit},userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Alumni/Getalumnisearch', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	
                    commit('alumnis_success',respdata);									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},						
		graduatestudent({commit}, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Alumni/Graduatestudent', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {		
                    commit('alumnis_success',respdata);								
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        }, 
		ReversestudentGraduation({rootState}, StudentId){
			return new Promise((resolve, reject) => {				
				axios.get(`/Alumni/ReversestudentGraduation?StudentId=${StudentId}&username=${rootState.auth.token}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {	                  							
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        },       
	}
}

export default alumni