import axios from 'axios';

const mock = {
    state: {  
		mocks : []
	},	
	mutations: {		
		mock_success(state, payload){			
			state.mocks = payload	
		}	
	},
	actions: {
        getmock({commit, rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Mock/Getmock', data: {id: '1', searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('mock_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getmocksearch({commit, rootState}, item){
			return new Promise((resolve, reject) => {				
				axios({url: '/Mock/Getmocksearch', data: {id: '1', searchitem: item, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('mock_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getmockbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Mock/Getmockbyid', data: {id: Id, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getmockbyclass({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Mock/Getmockbyclass', data: {id: Id, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },   	
		mockregistration({commit}, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Mock/Registermock', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('mock_success',payload);					
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}
	}
}

export default mock