import axios from 'axios';

const schsite = {
    
	actions: {
        processwebsiteheader(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schwebsite/Processwebsiteheader', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		getwebsiteheader({rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schwebsite/Getwebsiteheader', data: {id: 1, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},			
		getwebsiteheaderbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schwebsite/Getwebsiteheaderbyid', data: {id: Id, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		getwebsiteheaderforwebsite(schkey){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schwebsite/Getwebsiteheaderforwebsite', data: {RegKey:schkey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
		getentranceregistration({rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schwebsite/Getentranceregistration', data: {id: 1, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		removeentranceregistration({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schwebsite/Removeentranceregistration', data: {id: Id, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		getentranceregistrationsearch({rootState},item){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schwebsite/Getentranceregistrationsearch', data: {id: '1', searchitem: item, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},			
		
	}
}

export default schsite