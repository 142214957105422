import axios from 'axios';

const studentarchievement = {
    state: {  
		studentarcievements : []
	},	
	mutations: {		
		studentarcievements_success(state, payload){			
			state.studentarcievements = payload	
		}	
	},
	actions: {
        getstudentarchievement({rootState},StudId){
			return new Promise((resolve, reject) => {				
				axios({url: '/Studentarchievement/Getstudentarchievement', data: {id: StudId, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {						
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},			
		getstudentarchievementbyid(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Studentarchievement/Getstudentarchievementbyid', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getstudentarchievementsearch(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Studentarchievement/Getstudentarchievementsearch', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},						
		studentachievementregistration(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Studentarchievement/Registerstudentarchievement', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        },        
	}
}

export default studentarchievement