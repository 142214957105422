<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <InternetAlert />
    <StaffNavbar v-if="$store.state.auth.user_status_id == 1"></StaffNavbar>
    <StudentNavbar
      v-if="
        $store.state.auth.user_status_id == 2 ||
          $store.state.auth.user_status_id == 8
      "
    ></StudentNavbar>
    <AdminNavbar
      v-if="
        $store.state.auth.user_status_id == 6 ||
          $store.state.auth.user_status_id == 7 ||
          $store.state.auth.user_status_id == 9 ||
           $store.state.auth.user_status_id == 11
      "
    ></AdminNavbar>

    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->

          <router-view> </router-view>
        </fade-transition>

        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import StaffNavbar from "./StaffNavbar.vue";
import StudentNavbar from "./StudentNavbar.vue";
import AdminNavbar from "./AdminNavbar.vue";
import { FadeTransition } from "vue2-transitions";
import InternetAlert from "../views/InternetAlert.vue";
export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    StaffNavbar,
    StudentNavbar,
    AdminNavbar,
    InternetAlert,
    FadeTransition,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
  mounted() {},
};
</script>
<style lang="scss"></style>
