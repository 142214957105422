import axios from 'axios';

const staff = {
    state: {  
		staffs : []
	},	
	mutations: {		
		staffs_success(state, payload){			
			state.staffs = payload	
		}	
	},
	actions: {
        getstaffs({commit, rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Staff/Getstaffs', data: {id: '1', searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('staffs_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		GetDeletedStaffs({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Staff/GetDeletedStaffs?username=${rootState.auth.token}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getstaffbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Staff/Getstaffbyid', data: {id: Id, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		deletestaff({commit, rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Staff/Deletestaff', data: {id: Id, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	
					commit('staffs_success',respdata);									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		registerstaff({commit}, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Staff/Registerstaff', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('staffs_success',payload);					
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        },
        getserviceno({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Staff/Getserviceno?username=${rootState.auth.token}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
	}
}

export default staff