import axios from 'axios';

const subjectteacher = {   
	actions: {
        getsubjectteacher({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Subjectteacher/GetSubjectTeacher?username=${rootState.auth.token}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {	                   									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		GetSubjectTeacherByStaffId({rootState},StaffId){
			return new Promise((resolve, reject) => {				
				axios.get(`/Subjectteacher/GetSubjectTeacherByStaffId?StaffId=${StaffId}&username=${rootState.auth.token}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {	                   									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		deletesubjectteacher({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios.get(`/Subjectteacher/DeleteSubjectTeacher?Id=${Id}&username=${rootState.auth.token}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		processsubjectteacher(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Subjectteacher/ProcessSubjectTeacher', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   								
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        },        	
	}
}

export default subjectteacher