import axios from 'axios';
const appdatas = {	
    state: { 	
		schooldetails : {},	
		schooltypes : [],
		schooloperations : [],
		bankitems : [],
		othersgrades:[],
		conducts:[],
		cbtyears:null,
		privacypolicy:'',
		editorUrl:'https://cdn.ckeditor.com/4.14.0/full/ckeditor.js'
	},	
	mutations: {
		schooldetails_success(state, payload){			
			state.schooldetails = payload	
		},		
		schooltypes_success(state, payload){			
			state.schooltypes = payload	
		},
		schooloperations_success(state, payload){			
			state.schooloperations = payload	
		},
		banks_success(state, payload){			
			state.bankitems = payload	
		},
		othersgrades_success(state, payload){			
			state.othersgrades = payload	
		},
		conducts_success(state, payload){			
			state.conducts = payload	
		},
		cbtyears_success(state, payload){			
			state.cbtyears = payload	
		},
		privacypolicy_success(state, payload){			
			state.privacypolicy = payload	
		},
		check_internet (state,disenableifNotOnline) {
        state.disenableifNotOnline = disenableifNotOnline;       
      }


	},
	actions: {        
		getschoolbystaff({commit, rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/School/Getschoolbystaff?username=${rootState.auth.token}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					commit('schooldetails_success',respdata);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getschooltypes({commit, rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Appdatas/Getschooltype?username=${rootState.auth.token}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('schooltypes_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		getschooloperation({commit, rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Appdatas/Getschooloperation?accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('schooloperations_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		getbank({commit, rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Appdatas/Getbank?accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {										
					commit('banks_success',respdata);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		getothersgrade({commit, rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Appdatas/Getothersgrade?username=${rootState.auth.token}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('othersgrades_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		getcbtyear({commit, rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Appdatas/Getcbtyear?accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {										
					commit('cbtyears_success',respdata);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		processprivacypolicy({commit, rootState},item){
			return new Promise((resolve, reject) => {				
				axios.get(`/Appdatas/Processprivacypolicy?privacyandPolicy=${item}&username=${rootState.auth.token}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {										
					commit('privacypolicy_success',respdata);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		getprivacypolicy({commit,rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Appdatas/Getprivacypolicy?accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {										
					commit('privacypolicy_success',respdata);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		getsmsbalance({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Appdatas/Getsmsbalance?username=${rootState.auth.token}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {						
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
	}
}

export default appdatas