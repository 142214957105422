import axios from "axios";

const cbt = {
  actions: {
    processcbtpractice(access, userData) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/Cbt/ProcessCbtPractice",
          data: userData,
          method: "POST",
        })
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getcbtpractice(access, userData) {
      return new Promise((resolve, reject) => {
        axios({ url: "/Cbt/GetCbtPractice", data: userData, method: "POST" })
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    startcbtpractice(access, userData) {
      return new Promise((resolve, reject) => {
        axios({ url: "/Cbt/StartCbtPractice", data: userData, method: "POST" })
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    endcbtpractice(access, userData) {
      return new Promise((resolve, reject) => {
        axios({ url: "/Cbt/CbtPractice", data: userData, method: "POST" })
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getcbtSubjectforpractice(context, userdata) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/Cbt/GetCbtSubjectforPractice",
          data: userdata,
          method: "POST",
        })
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getcbtyearforpractice({ rootState }, ExamSubjectId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Cbt/GetCbtYearforPractice?ExamSubjectId=${ExamSubjectId}&Username=${rootState.auth.token}&accesskey=${rootState.auth.accesskey}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getpracticequestionforcbt(context, userData) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/Cbt/GetPracticeQuestionforCbt",
          data: userData,
          method: "POST",
        })
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getpracticequestionforcbtbyid(context, userdata) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/Cbt/GetPracticeQuestionforCbtbyId",
          data: userdata,
          method: "POST",
        })
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    submitquestionanswered(context, userData) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/Cbt/SubmitQuestionAnswered",
          data: userData,
          method: "POST",
        })
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getresultsummary(context, userData) {
      return new Promise((resolve, reject) => {
        axios({ url: "/Cbt/GetResultSummary", data: userData, method: "POST" })
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getresultdetails(context, userData) {
      return new Promise((resolve, reject) => {
        axios({ url: "/Cbt/GetResultDetails", data: userData, method: "POST" })
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};

export default cbt;
