import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./modules/settings/auth.store";
import term from "./modules/settings/term.store";
import schclass from "./modules/settings/schclass.store";
import schoption from "./modules/settings/option.store";
import scharm from "./modules/settings/arm.store";
import schsubject from "./modules/settings/schsubject.store";
import schsubjecttopic from "./modules/settings/subjecttopic.store";
import mock from "./modules/settings/mock.store";
import mocksubject from "./modules/settings/mocksubject.store";
import skill from "./modules/settings/skill.store";
import conduct from "./modules/settings/conduct.store";
import grade from "./modules/settings/grade.store";
import appdatas from "./modules/settings/appdatas.store";
import usersdata from "./modules/settings/usersdata.store";
import addressinfo from "./modules/settings/addressinfo.store";
import schooldata from "./modules/settings/schooldata.store";
import student from "./modules/student/student.store";
import uploader from "./modules/settings/uploader.store";
import classattendance from "./modules/student/classattendance.store";
import classattendancemid from "./modules/student/classattendancemid_store";
import markrecord from "./modules/student/markrecord.store";
import markrecordmid from "./modules/student/markrecordmid.store";
import studentconduct from "./modules/student/studentconduct.store";
import studentconductmid from "./modules/student/studentconductmid.store";
import studentskill from "./modules/student/studentskill.store";
import studentskillmid from "./modules/student/studentskillmid.store";
import physicaldev from "./modules/student/physicaldev.store";
import physicaldevmid from "./modules/student/physicaldevmid.store";
import studentarchievement from "./modules/student/studentarchievement.store";
import promotion from "./modules/student/promotion.store";
import alumni from "./modules/student/alumni.store";
import noteonstudent from "./modules/student/noteonstudent.store";
import mockrecord from "./modules/student/mockrecord.store";
import homework from "./modules/student/homework.store";
import staff from "./modules/staff/staff.store";
import classteacher from "./modules/staff/classteacher.store";
import subjectteacher from "./modules/staff/subjectteacher.store";
import schquestion from "./modules/schcbt/schquestion.store";
import smsSender from "./modules/sms/smsSender.store";
import reportcard from "./modules/report/reportcard/reportcard.store";
import reportcardmid from "./modules/report/reportcard/reportcardmid.store";
import mockreport from "./modules/report/reportcard/mockreport.store";
import examquestion from "./modules/schcbt/examquestion.store";
import schentrancequestion from "./modules/schcbt/schentrancequestion.store";
import school from "./modules/admin/school.store";
import companystaff from "./modules/admin/companystaff.store";
import subjectsetup from "./modules/admin/subjectsetup.store";
import cbtexam from "./modules/admin/cbtexam.store";
import cbtexamsubject from "./modules/admin/cbtexamsubject.store";
import cbtquestion from "./modules/admin/cbtquestion.store";
import news from "./modules/admin/news.store";
import schsite from "./modules/website/schsite.store";
import entranceexam from "./modules/schcbt/entranceexam.store";
import cbt from "./modules/cbt/cbt.store";
import tcquestion from "./modules/schcbt/tcquestion.store";
import tcexamquestion from "./modules/schcbt/tcexamquestion.store";
import sub from "./modules/subscription/sub.store";
import smsSub from "./modules/subscription/smssub.store";
import reportcardsub from "./modules/subscription/reportcardsub.store";
import schoolbill from "./modules/finance/schoolbill.store";
import otherincome from "./modules/finance/otherincome.store";
import outcomepayment from "./modules/finance/outcomepayment.store";
import libraryCategory from "./modules/library/libraryCategory.store";
import libraryBook from "./modules/library/libraryBook.store";
import libraryBookStock from "./modules/library/libraryBookStock.store";
import libraryBookIssue from "./modules/library/libraryBookIssue.store";
import hostel from "./modules/hostel/hostel.store";
import hostelFloor from "./modules/hostel/hostelFloor.store"
import hostelRoom from "./modules/hostel/hostelRoom.store"
import hostelBed from "./modules/hostel/hostelBed.store"
import hostelStudentBed from "./modules/hostel/hostelStudentBed.store"
import LessonNote from "./modules/Academic/LessonNote"
import LessonPlan from "./modules/Academic/LessonPlan"
import ClassNote from "./modules/Academic/ClassNote"

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    auth,
    term,
    schclass,
    schoption,
    scharm,
    schsubject,
    mock,
    mocksubject,
    skill,
    conduct,
    grade,
    appdatas,
    usersdata,
    addressinfo,
    schooldata,
    student,
    uploader,
    classattendance,
    classattendancemid,
    markrecord,
    markrecordmid,
    studentconduct,
    studentskill,
    studentskillmid,
    studentconductmid,
    physicaldev,
    physicaldevmid,
    studentarchievement,
    promotion,
    alumni,
    noteonstudent,
    mockrecord,
    homework,
    staff,
    classteacher,
    subjectteacher,
    schquestion,
    smsSender,
    reportcard,
    reportcardmid,
    schsubjecttopic,
    mockreport,
    examquestion,
    sub,
    smsSub,
    reportcardsub,
    school,
    companystaff,
    subjectsetup,
    cbtexam,
    cbtexamsubject,
    cbtquestion,
    schentrancequestion,
    news,
    schsite,
    entranceexam,
    cbt,
    tcquestion,
    tcexamquestion,
    schoolbill,
    otherincome,
    outcomepayment,
    libraryCategory,
    libraryBook,
    libraryBookStock,
    libraryBookIssue,
    hostel,
    hostelFloor,
    hostelRoom,
    hostelBed,
    hostelStudentBed,
    LessonNote,
    LessonPlan,
    ClassNote
  }
});
