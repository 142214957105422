import axios from 'axios';

const hostelFloor = {
    namespaced: true,
    
	actions: {		
		ProcessHostelFloor(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/HostelFloor/ProcessHostelFloor', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}, 		
		GetHostelFloor({rootState},HostelId){
			return new Promise((resolve, reject) => {				
				axios.get(`/HostelFloor/GetHostelFloor?HostelId=${HostelId}&Username=${rootState.auth.token}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}, 
		GetByIdHostelFloor({rootState},{HostelId,Id}){
			return new Promise((resolve, reject) => {				
				axios.get(`/HostelFloor/GetByIdHostelFloor?Id=${Id}&HostelId=${HostelId}&Username=${rootState.auth.token}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}
	}
}

export default hostelFloor