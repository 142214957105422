import axios from 'axios';
const outcomepayment = {   
	actions: {
        ProcessOutcomePayment(context,userdata){
			return new Promise((resolve, reject) => {				
				axios.post('/OutcomePayment/ProcessOutcomePayment', userdata)
				.then(resp => resp.data)
				.then(respdata => {						
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		GetOutcomePayment({rootState},{SessionId,TermId}){
			return new Promise((resolve, reject) => {				
				axios.get(`/OutcomePayment/GetOutcomePayment?SessionId=${SessionId}&TermId=${TermId}&Username=${rootState.auth.token}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
		GetOutcomePaymentById({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios.get(`/OutcomePayment/GetOutcomePaymentById?Id=${Id}&Username=${rootState.auth.token}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
		GetOutcomePaymentBySearch({rootState},{SearchItem,SessionId,TermId}){
			return new Promise((resolve, reject) => {				
				axios.get(`/OutcomePayment/GetOutcomePaymentBySearch?SearchItem=${SearchItem}&SessionId=${SessionId}&TermId=${TermId}&Username=${rootState.auth.token}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
        
	}
}

export default outcomepayment