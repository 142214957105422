import axios from 'axios';
const schoolbill = {   
	actions: {
        processschoolbill(context,userdata){
			return new Promise((resolve, reject) => {				
				axios.post('/SchoolBill/ProcessSchoolBill', userdata)
				.then(resp => resp.data)
				.then(respdata => {						
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getallschoolbill({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/SchoolBill/GetAllSchoolBill?Username=${rootState.auth.token}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getbyIdschoolbill({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios.get(`/SchoolBill/GetByIdSchoolBill?Id=${Id}&Username=${rootState.auth.token}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		searchschoolbill({rootState},SearchItem){
			return new Promise((resolve, reject) => {				
				axios(`/SchoolBill/SearchSchoolBill?SearchItem=${SearchItem}&Username=${rootState.auth.token}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {														
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		processschoolbilldetails(context,Userdata){
			return new Promise((resolve, reject) => {				
				axios.post(`/SchoolBill/ProcessSchoolBillDetails`,Userdata)
				.then(resp => resp.data)
				.then(respdata => {														
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getallschoolbilldetails({rootState},{ClassOptionId,SessionId,TermId}){
			return new Promise((resolve, reject) => {				
				axios.get(`/SchoolBill/GetAllSchoolBillDetails?ClassOptionId=${ClassOptionId}&SessionId=${SessionId}&TermId=${TermId}&Username=${rootState.auth.token}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },	
        getsumschoolbilldetails({rootState},{ClassOptionId,SessionId,TermId}){
			return new Promise((resolve, reject) => {				
				axios.get(`/SchoolBill/GetSumSchoolBillDetails?ClassOptionId=${ClassOptionId}&SessionId=${SessionId}&TermId=${TermId}&Username=${rootState.auth.token}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },		
        getbyidschoolbilldetails({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios.get(`/SchoolBill/GetByIdSchoolBillDetails?Id=${Id}&Username=${rootState.auth.token}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },	
        searchschoolbilldetails({rootState},{SearchItem,ClassOptionId,SessionId,TermId}){
			return new Promise((resolve, reject) => {				
				axios.get(`/SchoolBill/SearchSchoolBillDetails?SearchItem=${SearchItem}&ClassOptionId=${ClassOptionId}&SessionId=${SessionId}&TermId=${TermId}&Username=${rootState.auth.token}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
		processSchoolBillPayment(context,userdata){
			return new Promise((resolve, reject) => {				
				axios.post('/SchoolBill/ProcessSchoolBillPayment', userdata)
				.then(resp => resp.data)
				.then(respdata => {						
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		getSchoolBillPayment({rootState},{StudentId,SessionId,TermId}){
			return new Promise((resolve, reject) => {				
				axios.get(`/SchoolBill/GetSchoolBillPayment?StudentId=${StudentId}&SessionId=${SessionId}&TermId=${TermId}&Username=${rootState.auth.token}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
		getByIdSchoolBillPayment({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios.get(`/SchoolBill/GetByIdSchoolBillPayment?Id=${Id}&Username=${rootState.auth.token}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },	

	}
}

export default schoolbill