import axios from 'axios';

const mocksubject = {
    state: {  
		mocksubjects : []
	},	
	mutations: {		
		mock_success(state, payload){			
			state.mocksubjects = payload	
		}	
	},
	actions: {
        getmocksubject(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Mocksubject/Getmocksubject', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
			
		getavailablemocksubject(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Mocksubject/Getavailablemocksubject', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },	
        getmocksubjectbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Mocksubject/Getmocksubjectbyid', data: {id: Id, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		registermocksubject(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Mocksubject/Registermocksubject', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
										
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}
	}
}

export default mocksubject