import axios from 'axios';
const auth = {

    state: {
		status: '', 
		token: localStorage.getItem('token') || '', 
		userdata : null,
		user_fullName: '',
		user_phone:'',
		user_email:'',
		user_passport:'',
		user_status_id:'',
		orgid:null,
		username:'',
		baseurl:'http://localhost/SchoolDockAPI/',
		accesskey:'DxaGiSPm/CKRlSaPQnKCv3rQvV5Rj7MoPdHcZ79UvQg='
	},	
	mutations: {
		auth_request(state){
			state.status = 'loading';
		},
		auth_success(state, {token, user_fullName, user_phone, user_email, user_passport, user_status_id,org_id}){
			state.status = 'success'
			state.token = token			
			state.user_fullName = user_fullName
			state.user_phone=user_phone
			state.user_email=user_email
			state.user_passport= user_passport
			state.user_status_id = user_status_id
			state.orgid = org_id;
		},
		auth_username(state, username){
			state.status = 'yet to logon'
			state.username = username		
		},
		auth_error(state){
			state.status = 'error'
		},
		logout(state){
			state.status = ''
			state.token = ''
		},
		
	},
	actions: {
		passcodeaccess({commit, rootState}, passcode){
			return new Promise((resolve, reject) => {
				commit('auth_request')				
				axios({url: '/Account/Passcodeaccess', data: {id: 1, searchitem: passcode, username : 'rootState.auth.token', accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(loginuser => {
					const token = loginuser.UserName;
					const user_fullName = loginuser.FullName;
					const user_phone = loginuser.PhoneNo;
					const user_email = loginuser.EmailAddress;
					const org_id=loginuser.OrgId;
					let user_passport = null;
					if(loginuser.Passport!=null){
						user_passport=	loginuser.Passport
					}
					
					const user_status_id = loginuser.Status_Id;
					localStorage.setItem('token', token);
					// Add the following line:
					axios.defaults.headers.common['Authorization'] = token
					commit('auth_success',{token, user_fullName, user_phone, user_email, user_passport, user_status_id,org_id});					
					resolve(loginuser)
				})
				.catch(err => {
					commit('auth_error')
					localStorage.removeItem('token')
					reject(err)
				})
			})
		},
		login({commit}, userdata){
			return new Promise((resolve, reject) => {
				commit('auth_request')
				commit('auth_username',userdata.username)
				axios({url: '/account/userlogin', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(loginuser => {
					const token = loginuser.UserName;
					const user_fullName = loginuser.FullName;
					const user_phone = loginuser.PhoneNo;
					const user_email = loginuser.EmailAddress;
					const org_id=loginuser.OrgId;
					let user_passport = null;
					if(loginuser.Passport!=null){
						user_passport=	loginuser.Passport
					}					
					const user_status_id = loginuser.Status_Id;
					localStorage.setItem('token', token);
					// Add the following line:
					axios.defaults.headers.common['Authorization'] = token
					commit('auth_success',{token, user_fullName, user_phone, user_email, user_passport, user_status_id,org_id});					
					resolve(loginuser)
				})
				.catch(err => {
					commit('auth_error')
					localStorage.removeItem('token')
					reject(err)
				})
			})
		},
		resetpassword({commit}, userdata){
			return new Promise((resolve, reject) => {
				commit('auth_request')
				axios({url: '/account/Retrivepassword', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(loginuser => {
					resolve(loginuser)
				})
				.catch(err => {
					commit('auth_error')
					localStorage.removeItem('token')
					reject(err)
				})
			})
		},
		changepassword({rootState}, newpassword){
			return new Promise((resolve, reject) => {				
				axios.get(`/account/Changepassword?newpassword=${newpassword}&username=${rootState.auth.token}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(loginuser => {
					resolve(loginuser)
				})
				.catch(err => {					
					localStorage.removeItem('token')
					reject(err)
				})
			})
		},
		changenewpassword({rootState}, {newpassword,username}){
			return new Promise((resolve, reject) => {				
				axios.get(`/account/Changepassword?newpassword=${newpassword}&username=${username}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(loginuser => {
					resolve(loginuser)
				})
				.catch(err => {					
					localStorage.removeItem('token')
					reject(err)
				})
			})
		},
		studentsignup(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/account/Studentsignup', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(res => {
					resolve(res)
				})
				.catch(err => {					
					reject(err)
				})
			})
		},
		schoolsignup(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/account/Schoolsignup', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(res => {
					resolve(res)
				})
				.catch(err => {					
					reject(err)
				})
			})
		},
		logout({commit}){
			return new Promise((resolve) => {
				commit('logout')
				localStorage.removeItem('token')
				delete axios.defaults.headers.common['Authorization']
				resolve()
			})
		}
	},
	getters : {
		isLoggedIn: state => !!state.token,
		authStatus: state => state.status		
	}
}

export default auth