import axios from 'axios';
const school = {    
	actions: {
        processschool(context, UserData){
			return new Promise((resolve, reject) => {				
				axios({url: '/School/Processschool', data: UserData, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
        getschool({rootState}){
			return new Promise((resolve, reject) => {				
				axios({url: '/School/Getschool', data: {id: '1', searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},			
		getschoolsearch({rootState}, item){
			return new Promise((resolve, reject) => {				
				axios({url: '/School/Getschoolsearch', data: {id: '1', searchitem: item, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getschoolbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/School/Getschoolbyid', data: {id: Id, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
        deleteschool({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/School/Deleteschool', data: {id: Id, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
        activateschool({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/School/Activateschool', data: {id: Id, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		processschoolstaff(context, UserData){
			return new Promise((resolve, reject) => {				
				axios({url: '/School/Processschoolstaff', data: UserData, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		getschoolstaff({rootState},OrgId){
			return new Promise((resolve, reject) => {				
				axios({url: '/School/Getschoolstaff', data: {id: OrgId, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		getschoolstaffssearch({rootState},{OrgId,item}){
			return new Promise((resolve, reject) => {				
				axios({url: '/School/Getschoolstaffssearch', data: {id: OrgId, searchitem: item, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
	}
}

export default school