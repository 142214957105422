import axios from 'axios';

const schoption = {
    state: {  
		schoptions : []
	},	
	mutations: {		
		option_success(state, payload){			
			state.schoptions = payload	
		}	
	},
	actions: {
        getoption({commit, rootState},ClassId){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schoption/Getoption', data: {id: ClassId, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('option_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getoptionsearch({commit, rootState}, item){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schoption/Getoptionsearch', data: {id: '1', searchitem: item, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('option_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getoptionbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schoption/Getoptionbyid', data: {id: Id, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		getoptioninclassassigned({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schoption/Getoptioninclassassigned', data: {id: Id, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	
														
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		getoptioninsubjectassigned({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schoption/Getoptioninsubjectassigned', data: {id: Id, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	
														
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getoptionbyclass({rootState},ClassId){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schoption/Getoptionbyclass', data: {id: ClassId, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		getoptionbymock({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schoption/Getoptionbymock', data: {id: Id, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		optionregistration({commit}, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schoption/Registereoption', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('option_success',payload);					
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}
	}
}

export default schoption