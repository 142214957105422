import axios from 'axios';

const reportcardmid = {
    
	actions: {
		getreportmid({rootState}, {SessionId,TermId,StudId}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Reportcardmid/Getreportcard?SessionId=${SessionId}&TermId=${TermId}&StudId=${StudId}&Username=${rootState.auth.token}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {						
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},        
		getheadteacherrepcommentmid(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Reportcardmid/Getheadteacherrepcomment', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},    
		processheadteacherrepcommentmid(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Reportcardmid/Processheadteacherrepcomment', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},  
		getclassteacherrepcommentmid(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Reportcardmid/Getclassteacherrepcomment', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}, 
		processclassteacherrepcommentmid(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Reportcardmid/Processclassteacherrepcomment', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},  
		sendreportmid({rootState}, {SessionId,TermId,StudId}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Reportcardmid/Sendreport?SessionId=${SessionId}&TermId=${TermId}&StudId=${StudId}&Username=${rootState.auth.token}&Accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		}, 
		getbroadsheetmid(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Reportcardmid/Getbroadsheet', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {						
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},  
	}
}

export default reportcardmid