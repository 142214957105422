<template>
  <side-bar
    :background-color="sidebarBackground"
    short-title="SchoolDock"
    title="SchoolDock"
    v-if="$store.state.auth.user_status_id == 1"
  >
    <template slot="links">
      <sidebar-item
        :link="{
          name: 'Dashboard',
          icon: 'fa fa-tv text-primary',
          path: '/dashboard',
        }"
      />
      <sidebar-item
        :link="{
          name: 'Registrar',
          icon: 'fa fa-id-card text-blue',
          path: '/registrar',
        }"
        v-if="$store.state.usersdata.staffdetails.IsRegistral == true"
      />
      <sidebar-item
        :link="{
          name: 'Academic',
          icon: 'fa fa-chalkboard-teacher text-blue',
          path: '/academic',
        }"
      />
      <sidebar-item
        :link="{
          name: 'Student Record',
          icon: 'fa fa-clipboard text-blue',
          path: '/student',
        }"       
      />   
      <sidebar-item
        :link="{
          name: 'Hostel',
          icon: 'fa fa-bed text-blue',
          path: '/hostel',
        }"
       v-if="$store.state.usersdata.staffdetails.IsHostelMaster==true"
      />
      <sidebar-item
        :link="{
          name: 'Library',
          icon: 'fa fa-book-reader text-blue',
          path: '/library',
        }"
       
       v-if="$store.state.usersdata.staffdetails.IsLibraryAdmin==true"/>
      <sidebar-item
        :link="{
          name: 'Finance',
          icon: 'fa fa-cash-register text-blue',
          path: '/finance',
        }"
        v-if="$store.state.usersdata.staffdetails.IsBursal == true"
      />
      <sidebar-item
        :link="{
          name: 'Notification',
          icon: 'fa fa-bell text-blue',
          path: '/notification',
        }"
        v-if="$store.state.usersdata.staffdetails.IsPortalAdmin == true"
      />
       <sidebar-item
        :link="{
          name: 'Chat',
          icon: 'fab fa-rocketchat text-blue',
          path: '/notification',
        }"
        v-if="$store.state.usersdata.staffdetails.IsPortalAdmin == true"
      />
       <sidebar-item
        :link="{
          name: 'SMS',
          icon: 'fas fa-sms text-blue',
          path: '/smsmenu',
        }"
        v-if="$store.state.usersdata.staffdetails.IsPortalAdmin == true"
      />
      <sidebar-item
        :link="{
          name: 'Website',
          icon: 'fa fa-window-restore text-blue',
          path: '/schsitemenu',
        }"
        v-if="$store.state.usersdata.staffdetails.IsPortalAdmin == true"
      />
      <sidebar-item
        :link="{
          name: 'Report',
          icon: 'fa fa-chart-bar text-blue',
          path: '/reportmenu',
        }"       
      />
      
      <sidebar-item
        :link="{
          name: 'Setting',
          icon: 'fa fa-sliders-h text-blue',
          path: '/setting',
        }"
         v-if="$store.state.usersdata.staffdetails.IsPortalAdmin == true"
      />
     
      <sidebar-item
        :link="{
          name: 'Subscription',
          icon: 'fa fa-money-check text-blue',
          path: '/subscription',
        }"
        v-if="$store.state.usersdata.staffdetails.IsPortalAdmin == true"
      />
      <sidebar-item
        :link="{
          name: 'Profile',
          icon: 'fa fa-user text-blue',
          path: '/profile',
        }"
       
      />
      <sidebar-item
        :link="{
          name: 'Help',
          icon: 'fa fa-question text-blue',
          path: '/dashboard',
        }"
       
      />
    </template>
  </side-bar>
</template>
<script>
export default {
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
};
</script>