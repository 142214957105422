import axios from 'axios';
const subjectsetup = {    
	actions: {
        processsubjectsetup(context, UserData){
			return new Promise((resolve, reject) => {				
				axios({url: '/Subjectsetup/Processsubjectsetup', data: UserData, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
        getsubjectsetup({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Subjectsetup/Getsubjectsetup', data: {id: Id, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},			
		getsubjectsetupsearch({rootState}, {Id,item}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Subjectsetup/Getsubjectsetupsearch', data: {id: Id, searchitem: item, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getsubjectsetupbyid({rootState},{Id,Idd}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Subjectsetup/Getsubjectsetupbyid', data: {id: Id, idd:Idd, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
       
	}
}

export default subjectsetup