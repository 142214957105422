import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import store from './store/index'
import Axios from 'axios'
import moment from 'moment'
import AOS from 'aos'
import 'aos/dist/aos.css'
import SDockDashboard from './plugins/sdock-dashboard'



Vue.prototype.$http = Axios;
Axios.defaults.baseURL = 'http://localhost/SchoolDockAPI/api';

Vue.config.productionTip = false
Vue.filter('formatDate', function(value){
  if(value){
    return moment(String(value)).format('DD/MM/YYYY')
  }
});

Vue.use(SDockDashboard)
new Vue({ 
  router,
  store,
  AOS,
  render: h => h(App)
}).$mount('#app')
