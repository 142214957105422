import axios from 'axios';

const cbtquestion = {
    
	actions: {
        processcbtquestion(context, UserData){
			return new Promise((resolve, reject) => {				
				axios({url: '/Cbtquestion/Processcbtquestion', data: UserData, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		getcbtquestion({rootState},{SubjectId,ExamYearId}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Cbtquestion/Getcbtquestion', data: {id: SubjectId, idd:ExamYearId, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},			
		getcbtquestionbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Cbtquestion/Getcbtquestionbyid', data: {id: Id, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		getcbtquestionsearch({rootState},{SubjectId,ExamYearId,item}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Cbtquestion/Getcbtquestionsearch', data: {id: SubjectId, idd:ExamYearId, searchitem: item, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		
	}
}

export default cbtquestion