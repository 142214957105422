import axios from 'axios';

const tcexamquestion = {
   
	actions: {        			
		tcverifyaccesscode({rootState},accesscode){
			return new Promise((resolve, reject) => {				
				axios({url: '/Tcexam/Verifyaccesscode', data: {id: 1, searchitem: accesscode, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		tcprocessquestionforexam(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Tcexam/Processschquestionforexam', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   								
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},		
		tcgetquestionforexam(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Tcexam/Getschquestionforexam', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   								
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		tcgetquestionforexambyid({rootState},{Id, HeaderId}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Tcexam/Getschquestionforexambyid', data: {id: Id, idd: HeaderId, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		tcprocesssanswer(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Tcexam/Processsanswer', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   								
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},	
		tcexamresult({rootState}, Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Tcexam/Schexamresult', data: {id: Id, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   								
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},	
	}
}

export default tcexamquestion