import axios from 'axios';

const noteonstudent = {
    state: {  
		noteonstudents : []
	},	
	mutations: {		
		noteonstudents_success(state, payload){			
			state.noteonstudents = payload	
		}	
	},
	actions: {
		getnoteonstudent({commit, rootState},StudentId){
			return new Promise((resolve, reject) => {				
				axios.get(`/Noteonstudent/Getnoteonstudent?StudentId=${StudentId}&username=${rootState.auth.token}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('schooltypes_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	      	
		getnoteonstudentbyid(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Noteonstudent/Getnoteonstudentbyid', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		getnoteonstudentsearch(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Noteonstudent/Getnoteonstudentsearch', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},						
		processnoteonstudent(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Noteonstudent/Registernoteonstudent', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        },        
	}
}

export default noteonstudent