import axios from "axios";

const ClassNote = {
    // namespaced: true,
    actions: {
        ProcessClassNote(context, userdata) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        `/ClassNote/ProcessClassNote`,
                        userdata
                    )
                    .then((resp) => resp.data)
                    .then((respdata) => {
                        resolve(respdata);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        ApproveClassNote({ rootState }, ClassNoteId) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        `/ClassNote/ApproveClassNote?ClassNoteId=${ClassNoteId}&username=${rootState.auth.token}&accesskey=${rootState.auth.accesskey}`
                    )
                    .then((resp) => resp.data)
                    .then((respdata) => {
                        resolve(respdata);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        DisapproveClassNote({ rootState }, { ClassNoteId, Comment }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(
                        `/ClassNote/DisapproveClassNote?ClassNoteId=${ClassNoteId}&Comment=${Comment}&username=${rootState.auth.token}&accesskey=${rootState.auth.accesskey}`
                    )
                    .then((resp) => resp.data)
                    .then((respdata) => {
                        resolve(respdata);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        DeleteClassNote({ rootState }, { ClassNoteId, Comment }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(
                        `/ClassNote/DeleteClassNote?ClassNoteId=${ClassNoteId}&Comment=${Comment}&username=${rootState.auth.token}&accesskey=${rootState.auth.accesskey}`
                    )
                    .then((resp) => resp.data)
                    .then((respdata) => {
                        resolve(respdata);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        GetClassNotes({ rootState }, TermId ) {
            return new Promise((resolve, reject) => {
                axios
                    .get(
                        `/ClassNote/GetClassNotes?TermId=${TermId}&username=${rootState.auth.token}&accesskey=${rootState.auth.accesskey}`
                    )
                    .then((resp) => resp.data)
                    .then((respdata) => {
                        resolve(respdata);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        GetClassNotesPending({ rootState }, TermId) {
            return new Promise((resolve, reject) => {
                axios
                    .get(
                        `/ClassNote/GetClassNotesPending?TermId=${TermId}&username=${rootState.auth.token}&accesskey=${rootState.auth.accesskey}`
                    )
                    .then((resp) => resp.data)
                    .then((respdata) => {
                        resolve(respdata);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        GetClassNotesApproved({ rootState }, TermId) {
            return new Promise((resolve, reject) => {
                axios
                    .get(
                        `/ClassNote/GetClassNotesApproved?TermId=${TermId}&username=${rootState.auth.token}&accesskey=${rootState.auth.accesskey}`
                    )
                    .then((resp) => resp.data)
                    .then((respdata) => {
                        resolve(respdata);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        GetClassNotesDisApproved({ rootState }, TermId) {
            return new Promise((resolve, reject) => {
                axios
                    .get(
                        `/ClassNote/GetClassNotesDisApproved?TermId=${TermId}&username=${rootState.auth.token}&accesskey=${rootState.auth.accesskey}`
                    )
                    .then((resp) => resp.data)
                    .then((respdata) => {
                        resolve(respdata);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        GetClasssNotesDeleted({ rootState }, TermId) {
            return new Promise((resolve, reject) => {
                axios
                    .get(
                        `/ClassNote/GetClasssNotesDeleted?TermId=${TermId}&username=${rootState.auth.token}&accesskey=${rootState.auth.accesskey}`
                    )
                    .then((resp) => resp.data)
                    .then((respdata) => {
                        resolve(respdata);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        GetByIdClassNote({ rootState }, Id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(
                        `/ClassNote/GetByIdClassNote?Id=${Id}&username=${rootState.auth.token}&accesskey=${rootState.auth.accesskey}`
                    )
                    .then((resp) => resp.data)
                    .then((respdata) => {
                        resolve(respdata);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        GetBySubjectClasssNote({ rootState }, { SubjectByClassId, TermId }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(
                        `/ClassNote/GetBySubjectClasssNote?SubjectByClassId=${SubjectByClassId}&TermId=${TermId}&username=${rootState.auth.token}&accesskey=${rootState.auth.accesskey}`
                    )
                    .then((resp) => resp.data)
                    .then((respdata) => {
                        resolve(respdata);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
};

export default ClassNote;
