<template>
<side-bar :background-color="sidebarBackground" short-title="SchoolDock" title="SchoolDock">
    <template slot="links">
        <sidebar-item :link="{name: 'Dashboard', icon: 'ni ni-tv-2 text-primary', path: '/dashboard'
            }" />
        <sidebar-item :link="{name: 'CBT', icon: 'ni ni-app text-blue', path: '/exammenu'}" />
         <sidebar-item :link="{name: 'i-Learning', icon: 'ni ni-books text-blue', path: '/exammenu'}" />
        <sidebar-item :link="{name: 'Home Work', icon: 'ni ni-ruler-pencil text-blue', path: '/studhomework'}" v-if="$store.state.auth.orgid" />
        <sidebar-item :link="{name: 'Archievement', icon: 'ni ni-trophy text-blue', path: '/Studarchive'}" v-if="$store.state.auth.orgid" />
        <sidebar-item :link="{name: 'Notice', icon: 'ni ni-notification-70 text-blue', path: '/studnoteonstudent'}" v-if="$store.state.auth.orgid" />
         <sidebar-item :link="{name: 'School Bill', icon: 'fa fa-money-bill text-blue', path: '/studentschoolbill'}" v-if="$store.state.auth.orgid" />
       
        <sidebar-item :link="{name: 'Report Card', icon: 'ni ni-chart-bar-32 text-blue', path: '/studreport'}" v-if="$store.state.auth.orgid" />

    </template>
</side-bar>
</template>
<script>
export default {
     data() {
      return {
        sidebarBackground: 'vue' //vue|blue|orange|green|red|primary
      };
    },
}
</script>
