import Vue from "vue";
import Router from "vue-router";
import store from "./store/index.js";
import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";
import HomeLayout from "@/layout/HomeLayout";
Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isLoggedIn) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isLoggedIn) {
    next();
    return;
  }
  next("/login");
};

export default new Router({
  linkExactActiveClass: "active",
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "dashboard",
      component: DashboardLayout,
      children: [
       
        {
          path: "/dashboard",
          name: "dashboard",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/Dashboard.vue"),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/news",
          name: "News",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/PortalAdmin/News.vue"),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/supportstaff",
          name: "support",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/PortalAdmin/Staff.vue"),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/profile",
          name: "profile",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/UserProfile.vue"),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/setting",
          name: "settings",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Setting/Index.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/session",
          name: "session",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Setting/Component/Session.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/term",
          name: "term",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Setting/Component/Term.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/class",
          name: "class",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Setting/Component/Schclass.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/option",
          name: "option",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Setting/Component/Schoption.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/arm",
          name: "arm",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Setting/Component/Scharm.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/schsubject",
          name: "subject",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Setting/Component/Schsubject.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/subjecttopic",
          name: "subject Topic",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Setting/Component/Subjecttopic.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/mock",
          name: "mock",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Setting/Component/Mock.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/mocksubject",
          name: "mock subject",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Setting/Component/Mocksubject.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/skill",
          name: "psycomotor skill",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Setting/Component/Skill.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/conduct",
          name: "Conduct",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Setting/Component/Conduct.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/grade",
          name: "Grade Scale",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Setting/Component/Gradescale.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/schooldetails",
          name: "School Details",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Setting/Component/Schooldetails.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
       
        {
          path: "/registrar",
          name: "Registrar",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Registrar/Index.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/studentreg",
          name: "student registration",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Registrar/Component/Student/Student.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/deletedstudent",
          name: "Deleted Student",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Registrar/Component/Student/DeletedStudent.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/promotion",
          name: "Student Promotion",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Registrar/Component/Student/Promotion.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/graduation",
          name: "Student Graduation",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Registrar/Component/Student/Graduation.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/alumni",
          name: "Alumni",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Registrar/Component/Student/Alumni.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/staff",
          name: "Staffs",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Registrar/Component/Staff/Staff.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/deleted-staff",
          name: "Staff Registration",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Registrar/Component/Staff/DeletedStaff.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/classteacher",
          name: "Class Teacher",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Registrar/Component/Staff/Classteacher.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/subjectteacher",
          name: "Subject Teacher",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Registrar/Component/Staff/Subjectteacher.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/studentachiement",
          name: "Student Achievement",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Registrar/Component/Student/Studentarchievement.vue"
            ),
          beforeEnter: ifAuthenticated,
        }, 
        {
          path: "/noteonstudent",
          name: "Note on Student",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Registrar/Component/Student/Noteonstudent.vue"
            ),
          beforeEnter: ifAuthenticated,
        },   
        {
          path: "/student",
          name: "Student Record",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Student/Index.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/classattendance",
          name: "Class Attendance",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Student/Component/Classattendance.vue"
            ),
          beforeEnter: ifAuthenticated,
        },        
        {
          path: "/markrecord",
          name: "Mark Record",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Student/Component/Markrecord.vue"
            ),
          beforeEnter: ifAuthenticated,
        },       
        {
          path: "/studentconduct",
          name: "Student Conduct",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Student/Component/Studentconduct.vue"
            ),
          beforeEnter: ifAuthenticated,
        },       
        {
          path: "/studentskill",
          name: "Student Psycomotor Assessment",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Student/Component/Studentskill.vue"
            ),
          beforeEnter: ifAuthenticated,
        },        
        {
          path: "/physicaldev",
          name: "Physical Development Assessment",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Student/Component/Physicaldev.vue"
            ),
          beforeEnter: ifAuthenticated,
        },  
        {
          path: "/mockrecord",
          name: "Mock Record",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Student/Component/Mockrecord.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/academic",
          name: "Academic",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Academic/Index.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/homework",
          name: "Student Home Work",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Academic/Component/Homework.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/homeworkanswer",
          name: "Student Home Work Answer",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Academic/Component/Homeworkanswer.vue"
            ),
          beforeEnter: ifAuthenticated,
        },         
        {
          path: "/schcbtheader",
          name: "CBT Header",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Academic/Component/CBT/Questionheader.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/schcbtquestion",
          name: "School CBT Question",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Academic/Component/CBT/Question.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/schentrance",
          name: "Entrance Exam",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Academic/Component/CBT/Entranceheader.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/schentrancequestion",
          name: "Entrance Exam Question",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Academic/Component/CBT/Entrancequestion.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/schcbtstudentanswer",
          name: "Exam Student Answer",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Academic/Component/CBT/Schcbtstudentanswer.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/entrancestudentanswer",
          name: "Entrance Student Answer",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Academic/Component/CBT/Entrancestudentanswer.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/shareschquestion",
          name: "Share School Question",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Academic/Component/CBT/Sharequestion.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/shareallschquestion",
          name: "Share All School Question",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Academic/Component/CBT/Shareallquestion.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/tccbtheader",
          name: "Tutorial Center CBT Header",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Academic/Component/CBT/TcQuestionHeader.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/tccbtquestion",
          name: "Tutorial Center CBT Question",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Academic/Component/CBT/TcQuestion.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/sharetcquestion",
          name: "Share CBT Question",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Academic/Component/CBT/TcSharequestion.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/sharealltcquestion",
          name: "Share All CBT Question",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Academic/Component/CBT/TcShareallquestion.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/lesson-note",
          name: "Lesson Note",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Academic/Component/LessonNote.vue"
            ),
          beforeEnter: ifAuthenticated,
        },       
        {
          path: "/lesson-plan",
          name: "Lesson Plan",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Academic/Component/LessonPlan.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/class-note",
          name: "Class Note",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Academic/ClassNote.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/smsmenu",
          name: "SMS",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/SMS/Index.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/smsguardian",
          name: "SMS to Guardian",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/SMS/Component/Guardiansms.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/smsstaff",
          name: "SMS to Staff",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/SMS/Component/Staffsms.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/smsother",
          name: "SMS to Others",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/SMS/Component/Othersms.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/tracksms",
          name: "Track SMS",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/SMS/Component/Tracksms.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/reportmenu",
          name: "Report",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Report/Index.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/report-card",
          name: "Student Report Card",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Report/Component/ReportCard.vue"
            ),
          beforeEnter: ifAuthenticated,
        },        
        {
          path: "/report-card-alumni",
          name: "Alumni Student Report Card",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Report/Component/ReportCardAlumni.vue"
            ),
          beforeEnter: ifAuthenticated,
        },       
        {
          path: "/mockreport",
          name: "Mock Report Card",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Report/Component/Mockreport.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/broadsheet",
          name: "Broadsheet Full Term ",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Report/Component/Broadsheet.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/broadsheetperposition",
          name: "Broadsheet Per Position Full Term ",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Report/Component/BroadsheetPerPosition.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/resgisteredstudent",
          name: "Registered Student",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Report/Component/Student/Registeredstudent.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/exammenu",
          name: "Exam",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalStudent/CBT/Index.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/schexam",
          name: "Exam Question",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalStudent/CBT/Component/Exam.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/studreport",
          name: "Student Report",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalStudent/Report/Index.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/studreportcard",
          name: "Report Card (Full Term)",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalStudent/Report/Component/Reportcard.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/studreportcardmid",
          name: "Report Card (Mid Term)",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalStudent/Report/Component/Reportcardmid.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/studhomework",
          name: "Home Work",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalStudent/Homework.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/Studhomeworkanswer",
          name: "Home Work Answer",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalStudent/Homeworkanswer.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/Studarchive",
          name: "Student Archievement",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalStudent/Studentarchievement.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/studnoteonstudent",
          name: "Student Note",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalStudent/Noteonstudent.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/school",
          name: "School",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/PortalAdmin/School.vue"),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/schoolstaff",
          name: "School Staff",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalAdmin/Schoolstaff.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/subjectsetup",
          name: "School Subject",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalAdmin/Subjectsetup.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/cbtexam",
          name: "School CBT Exam",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/PortalAdmin/Cbtexam.vue"),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/cbtexamsubject",
          name: "School CBT Exam Subject",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalAdmin/Cbtexamsubject.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/cbtquestion",
          name: "CBT Question",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalAdmin/Cbtquestion.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/privacysetup",
          name: "Privacy Policy Setup",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/PortalAdmin/Privacy.vue"),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/notification",
          name: "Notification",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Notification/Index.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/schnews",
          name: "News",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Notification/Component/Newssetup.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/readschnews",
          name: "read news",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Notification/Component/ReadNews.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/moreschnews",
          name: "more news",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Notification/Component/MoreNews.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
       
        {
          path: "/schsitemenu",
          name: "School Website",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Schsite/Index.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/headerbanner",
          name: "Website Header Banner",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Schsite/Component/Headerbanner.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/entranceregistered",
          name: "Entrance Registered",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Schsite/Component/Entranceregistration.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
  
        {
          path: "/studentcbtexam",
          name: "CBT Exam",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalStudent/CBT/Component/CbtExam.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/cbtprep",
          name: "CBT Prep",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalStudent/CBT/Component/Cbtprep.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/studentcbtsubject",
          name: "CBT Exam Subject",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalStudent/CBT/Component/Cbtexamsubject.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/cbtresultsummary",
          name: "CBT Result Summary",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalStudent/CBT/Component/CbtSummary.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/cbtresultdetails",
          name: "CBT Result Details",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalStudent/CBT/Component/CbtResultDetails.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
       
        {
          path: "/smsbyadmin",
          name: "SMS BY Admin",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/PortalAdmin/SmsSub.vue"),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/subscription",
          name: "Subscription",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Subscription/Index.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/reportcardsubscription",
          name: "Reportcard Subscription",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Subscription/Component/Reportcardsub.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/reportcardandcbtsubscription",
          name: "Reportcard and CBT Subscription",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Subscription/Component/ReportcardandCbt.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/sms-subscription",
          name: "SMS Subscription",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Subscription/Component/Sms.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/finance",
          name: "Finance",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Finance/Index.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/schoolbill",
          name: "School Bill",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Finance/Component/SchoolBill.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/schoolbillsetup",
          name: "School Bill Setup",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Finance/Component/SchoolBillSetup.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/studentschoolbill",
          name: "Student School Bill",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalStudent/StudentSchoolBill.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/schoolbillpayment",
          name: "School Bill Payment",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Finance/Component/SchoolBillPayment.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/otherincomepayment",
          name: "Other Income Payment",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Finance/Component/OtherIncomePayment.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/outcomepayment",
          name: "Outcome Payment",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Finance/Component/OutcomePayment.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/library",
          name: "Library",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Library/Index.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/libraryCategory",
          name: "Library Category",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Library/Component/LibraryCategory.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/libraryBook",
          name: "Library Book",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Library/Component/LibraryBook.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/libraryBookStock",
          name: "Library Book Stock",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Library/Component/LibraryBookStock.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/libraryBookIssue",
          name: "Library Book Issue",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Library/Component/LibraryBookIssue.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/libraryBookReturn",
          name: "Library Book Issue Return",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Library/Component/LibraryBookReturn.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/libraryBookStockSummary",
          name: "Library Book Stock Summary",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Library/Component/LibraryBookStockSummary.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/hostel",
          name: "Hostel",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Hostel/Index.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/hostelRegistration",
          name: "Hostel Registration",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Hostel/Component/Hostel.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/hostelFloor",
          name: "Hostel Floor",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Hostel/Component/HostelFloor.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/hostelRoom",
          name: "Hostel Room",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Hostel/Component/HostelRoom.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/hostelBed",
          name: "Hostel Bed",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Hostel/Component/HostelBed.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "/hostelStudentBed",
          name: "Hostel Student Bed",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalSchool/Hostel/Component/HostelStudentBed.vue"
            ),
          beforeEnter: ifAuthenticated,
        }
      ],
    },
    {
      path: "/",
      redirect: "login",
      component: AuthLayout,
      children: [
        
        {
          path: "/login",
          name: "login",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/Account/Login.vue"),
          beforeEnter: ifNotAuthenticated,
        },
        {
          path: "/register",
          name: "register",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/Account/Register.vue"
            ),
          beforeEnter: ifNotAuthenticated,
        },
        {
          path: "/retrievepassword",
          name: "retrieve password",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/Account/Retrievepassword.vue"
            ),
          beforeEnter: ifNotAuthenticated,
        },
        {
          path: "/changepassword",
          name: "change password",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/Account/Changepassword.vue"
            ),
          beforeEnter: ifNotAuthenticated,
        },
        {
          path: "/register",
          name: "Student Registration",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/Account/Register.vue"
            ),
          beforeEnter: ifNotAuthenticated,
        },
        {
          path: "/registersch",
          name: "School Registration",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/Account/Registersch.vue"
            ),
          beforeEnter: ifNotAuthenticated,
        },
        {
          path: "/privacy",
          name: "Privacy Policy",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/Privacy.vue"),
          beforeEnter: ifNotAuthenticated,
        },
        {
          path: "/entranceexam",
          name: "Student Entrance Exam",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/PortalStudent/CBT/Component/Entranceexam.vue"
            ),
          beforeEnter: ifNotAuthenticated,
        },
      ],
    },
    {
      path: "/",
      redirect: "home",
      component: HomeLayout,
      children: [
       
        {
          path: "/home",
          name: "home",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/Home.vue"),
          beforeEnter: ifNotAuthenticated,
        },
        {
          path: "/readnews",
          name: "read news External",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/ReadNews.vue"),
          beforeEnter: ifNotAuthenticated,
        },
        {
          path: "/morenews",
          name: "more news external",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/MoreNews.vue"),
          beforeEnter: ifNotAuthenticated,
        },
      ],
    },
  ],
});
