var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('InternetAlert'),(_vm.$store.state.auth.user_status_id == 1)?_c('StaffNavbar'):_vm._e(),(
      _vm.$store.state.auth.user_status_id == 2 ||
        _vm.$store.state.auth.user_status_id == 8
    )?_c('StudentNavbar'):_vm._e(),(
      _vm.$store.state.auth.user_status_id == 6 ||
        _vm.$store.state.auth.user_status_id == 7 ||
        _vm.$store.state.auth.user_status_id == 9 ||
         _vm.$store.state.auth.user_status_id == 11
    )?_c('AdminNavbar'):_vm._e(),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }