import axios from 'axios';

const entranceexam = {
   
	actions: {   
        verifyentrancestudent({rootState},regcode){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schentranceexam/Verifyentrancestudent', data: {id: 1, searchitem: regcode, username : regcode, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},     			
		verifyentranceaccesscode({rootState},accesscode){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schentranceexam/Verifyentranceaccesscode', data: {id: 1, searchitem: accesscode, username : accesscode, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		processschquestionforentranceexam(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schentranceexam/Processschquestionforentranceexam', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   								
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		getschquestionforentranceexam(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schentranceexam/Getschquestionforentranceexam', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   								
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		getschquestionforentranceexambyid({rootState},{Id, HeaderId, RegCode}){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schentranceexam/Getschquestionforentranceexambyid', data: {id: Id, idd: HeaderId, searchitem: null, username : RegCode, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
		processsentranceanswer(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schentranceexam/Processsentranceanswer', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   								
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},	
		schentranceexamresult({rootState}, Id){
			return new Promise((resolve, reject) => {				
				axios({url: '/Schexam/Schentranceexamresult', data: {id: Id, searchitem: null, username : rootState.auth.token, accesskey : rootState.auth.accesskey}, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {	                   								
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
		},	
	}
}

export default entranceexam