import axios from 'axios';

const companystaff = {    
	actions: {
        GetCompanyStaffs({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/CompanyStaff/GetCompanyStaffs?accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {										
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},			
		GetCompanyStaffById({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios.get(`/CompanyStaff/GetCompanyStaffById?id=${Id}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},		
		DeleteCompanyStaff({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios.get(`/CompanyStaff/DeleteCompanyStaff?id=${Id}&username=${rootState.auth.token}&accesskey=${rootState.auth.accesskey}`)
				.then(resp => resp.data)
				.then(respdata => {														
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},				
		ProcessCompanyStaff(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/CompanyStaff/ProcessCompanyStaff', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        },        
	}
}

export default companystaff