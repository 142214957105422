import axios from 'axios';

const schsubject = {
	state: {
		schsubject: []
	},
	mutations: {
		subject_success(state, payload) {
			state.schsubject = payload
		}
	},
	actions: {
		getschsubject(context, userdata) {
			return new Promise((resolve, reject) => {
				axios({ url: '/Schsubject/Getschsubject', data: userdata, method: 'POST' })
					.then(resp => resp.data)
					.then(respdata => {
						resolve(respdata)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		getschsubjectinsubjectassigned({ rootState }, Id) {
			return new Promise((resolve, reject) => {
				axios({ url: '/Schsubject/Getschsubjectinsubjectassigned', data: { id: Id, searchitem: null, username: rootState.auth.token, accesskey: rootState.auth.accesskey }, method: 'POST' })
					.then(resp => resp.data)
					.then(respdata => {
						resolve(respdata)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		GetAssignedSubjectByOption({ rootState }, OptionId) {
			return new Promise((resolve, reject) => {
				axios
					.get(
						`Schsubject/GetAssignedSubjectByOption?OptionId=${OptionId}&username=${rootState.auth.token}&accesskey=${rootState.auth.accesskey}`
					)
					.then((resp) => resp.data)
					.then((respdata) => {
						resolve(respdata);
					})
					.catch((err) => {
						reject(err);
					});
			});
		},
		getavailablesubject(context, userdata) {
			return new Promise((resolve, reject) => {
				axios({ url: '/Schsubject/Getavailablesubject', data: userdata, method: 'POST' })
					.then(resp => resp.data)
					.then(respdata => {
						resolve(respdata)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		getschsubjectbyid({ rootState }, Id) {
			return new Promise((resolve, reject) => {
				axios({ url: '/Schsubject/Getschsubjectbyid', data: { id: Id, searchitem: null, username: rootState.auth.token, accesskey: rootState.auth.accesskey }, method: 'POST' })
					.then(resp => resp.data)
					.then(respdata => {
						resolve(respdata)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		getschsubjectforstudent({ rootState }, StudId) {
			return new Promise((resolve, reject) => {
				axios({ url: '/Schsubject/Getschsubjectforstudent', data: { id: StudId, searchitem: null, username: rootState.auth.token, accesskey: rootState.auth.accesskey }, method: 'POST' })
					.then(resp => resp.data)
					.then(respdata => {
						resolve(respdata)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		registerschsubject(context, userdata) {
			return new Promise((resolve, reject) => {
				axios({ url: '/Schsubject/Registerschsubject', data: userdata, method: 'POST' })
					.then(resp => resp.data)
					.then(respdata => {

						resolve(respdata)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		GetSubjectByClass({ rootState }, ClassId) {
			return new Promise((resolve, reject) => {
				axios
					.get(
						`Schsubject/GetSubjectByClass?ClassId=${ClassId}&username=${rootState.auth.token}&accesskey=${rootState.auth.accesskey}`
					)
					.then((resp) => resp.data)
					.then((respdata) => {
						resolve(respdata);
					})
					.catch((err) => {
						reject(err);
					});
			});
		},
	}
}

export default schsubject